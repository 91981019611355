import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Pdf from "../static/pdf/C3_FSP_Executive_Summary_FINAL_2022.pdf"
import DataCount from '../components/dataCount'

import {
  grey,
  green,
  banner,
  content,
  container,
  button,
  uppercase,
  flex,
  card,
  thin,
  flexauto,
  larger,
  marginauto,
} from '../components/layout.module.css'


const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <div className={banner}>
        <StaticImage src="../images/banner-home.jpg" alt="" style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
        }}
        loading="eager"
        layout="fullWidth"></StaticImage>
        <div className={container}>
          <div className={content}>
            <h1>View our Flexible Services Program Executive Summary</h1>
            <a href={Pdf} target="_blank" rel="noreferrer noopener" className={button}>
              download now
            </a>
          </div>
        </div>
      </div>

      <div className={container}>      
      <h2>Social needs are health needs</h2>
        <div className={flex}>
          <StaticImage src="../images/christina_quote.jpg" objectFit="contain" alt="Our single largest aspirational goal for the MassHealth ACO Program is to alleviate health-related social needs. - Christina Severin, President & CEO"></StaticImage>
          <div className={marginauto}>
            <p>Creating conversations between members, healthcare providers, and community-based social service organizations in order to address the challenges getting in the way of a member’s health and wellbeing – their health-related social needs – is doing primary care well.</p><p>At C3, the Flexible Services Program gave us a way to do just that. Critical to our success is the investment in partnerships. We do not simply refer our members to the social services sector for support. Rather, we invest new funds in ways that allow our partners to build upon their deep roots in serving communities. Here you can learn more about this model and our approach, hear from our social service partners, and get tips and ideas to advance your members’ and community’s health-related social needs.</p>
          </div>
        </div>
      </div>
      <div className={container}>
        <p className={thin}>Community Care Cooperative (C3) is a MassHealth ACO created by Federally Qualified Health Centers (FQHCs) to transform the health of underserved communities. C3 includes a network of partner FQHCs and Affiliated Providers across Massachusetts.</p>
      </div>
      <div className={grey}>
        <div className={flex}>
            <Link to='/opportunity' className={card}>
              <StaticImage src="../images/opportunity/banner.jpg" alt=""></StaticImage>
              <h3>The Opportunity</h3>
              <p><strong>Massachusetts’ Medicaid waiver was an opportunity to rethink primary care delivery.</strong></p><p>Learn how we leveraged the Flexible Services Program to address health inequities by meeting the health-related social needs of our members through holistic, community-based care.</p>
            </Link>
            <Link to='/approach' className={card}>
              <StaticImage src="../images/banner-approach.jpg" alt=""></StaticImage>
              <h3>Our Approach</h3>
              <p><strong>We partnered with trusted community organizations that were already skilled in serving social service needs.</strong></p><p>Explore how we invested in partnerships with community organizations to facilitate closed looped referrals, ensuring members’ health-related social needs are met.</p>
            </Link>
            <Link to='/impact' className={card}>
              <StaticImage src="../images/impact/banner.jpg" alt=""></StaticImage>
              <h3>Our Impact</h3>
              <p><strong>Members are at the center of our work.</strong></p><p>Learn how the Flexible Services partnerships allowed mission-aligned health care and community partners to meet members’ health and social needs.</p>
            </Link>
            <Link to='/learnings' className={card}>
              <StaticImage src="../images/learnings/banner.jpg" alt=""></StaticImage>
              <h3>Learnings</h3>
              <p><strong>Wondering how you can play a pivotal role in the future of health care?</strong></p><p>Consider some of these ideas for inspiration on how to use a similar model to address health inequities. Tips and ideas for both health care and social services partners are featured.</p>
            </Link>
          </div>
      </div>

      <DataCount></DataCount>

      <div className={green}>
        <div className={container +' '+ flex +' '+ thin}>
          <div>
            <h3 className={uppercase}>Meet our team</h3>
            <p className={larger}>Connect with us to learn more about our experience in co-designing a program to address health-related social needs.</p>
          </div>
          
          <Link to="/our-team" className={button +' '+flexauto+' '+marginauto}>
            See our team
          </Link>
        </div>
      </div>

    </Layout>
  )
}

export default IndexPage