import * as React from 'react'
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';

import {
  counter,
} from '../components/layout.module.css'

const Count = ({ number, delay, children }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <h2 className={counter} ref={ref}>
      {inView ? <CountUp end={number} delay={delay} duration={1.25} easingFn='easeInOutExpo'/> : null}
      {children}
    </h2>
  )
}

export default Count