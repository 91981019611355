import * as React from 'react'
import Count from '../components/count'
import {
  container,
  flex,
  center,
  uppercase,
  white,
  blue,
  thin,
  larger,
} from '../components/layout.module.css'

const DataCount = () => {
  return (
    <div className={blue}>
    <div className={container}>
    <div className={center +' '+thin}>
      <h3>In the first two years of the program, we have served more than 7,000 members and continue to reach more and more people.</h3>
      <p>We've helped members from community health centers improve their food security or housing situation. Our members are incredibly diverse, speaking over 22 languages and representing many ethnic/racial groups.</p>
    </div>
      <div className={flex}>
        <div className={white}>
          <Count number={2000} delay={.2}>+</Count><span className={center+' '+uppercase+' '+larger}>connected with a housing expert:</span><p>We've provided intensive individualized support to address housing-related needs, such as resource navigation, application assistance, job training, income maximization, home modifications, and housing search and placement.</p>
        </div>
        <div className={white}>
          <Count number={6700} delay={.4}>+</Count><span className={center+' '+uppercase+' '+larger}>supported with nutrition services:</span><p>We've provided members with over $4.5M in food vouchers, 200,000 home delivered meals, and kitchen equipment and nutrition education to help members purchase, prepare, and store healthy foods.</p>
        </div>
      </div>
    </div>
    </div>
)}

export default DataCount